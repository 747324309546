<template>
  <div v-if="question">
    <label
      v-if="question && question.name"
      class="block text-sm font-medium leading-5 text-gray-700 mb-2"
      >{{ question.name }}</label
    >
    <a
      v-if="answer"
      class="text-blue-500 font-medium underline" @click="download(answer)"
    >
      <fa :icon="['far', 'paperclip']" class="mr-2 text-gray-700"/>
      <span>{{ answer.originalName }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'FileView',
  props: {
    formId: { type: String, required: false },
    question: { type: Object, required: true },
    answer: { type: Object },
    disabled: { type: Boolean },
    v: { type: Object },
  },
  computed: {
    prefix() {
      const fid = this.formId;
      const qid = this.question.id;
      const time = new Date().getTime();
      return `forms/${fid}/${qid}/${time}-`;
    },
  },
  methods: {
    async download(item) {
      const windowRef = window.open();
      if (!item) return;
      this.downloadError = null;
      try {
        const ref = this.$fb().storage().ref(item.fullPath);
        const url = await ref.getDownloadURL();
        // window.open(url, 'Download');
        windowRef.location = url;
      } catch (error) {
        console.log(error);
        this.downloadError = error;
      }
    },
  }
};
</script>
